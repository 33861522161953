.app-stack h1{
  font-size: var(--fontSize2);
}

.stack-action{
  font-size: var(--fontSize2);
  width: 100%; 
  
}
.stack-action button{
  width:100%;
  background: rgb(19, 25, 39);
  color: rgb(197, 197, 197);
  border: 1px solid rgb(96, 146, 105);
  padding: 0.5vw 1.5vw;
  border-radius: 1vw;
  cursor: pointer;
  font-size: var(--fontSize3);
  /* font-family: inherit; */
}
.stack-action button:hover {
  box-shadow: 0 0 0 1px rgb(177, 236, 122);
}
.stack-action button:active {
  transform: scale(0.96);
}

.stack-header, .stack-body{
  font-size: var(--fontSize4);
  background-color: rgb(71, 69, 73);
  color:rgb(148, 167, 167);
  width: 100%; 
  border-radius: 5px;
}
.stack-header{
  font-size: var(--fontSize3);
}

.stack-header td:nth-child(1), .stack-body td:nth-child(1){
  width: 10%;
}

.stack-header td:nth-child(2), .stack-body td:nth-child(2){
  width: 4%;
}

.stack-header td:nth-child(3), .stack-body td:nth-child(3){
  width: 45%;
  text-align: center;
}

.stack-header td:nth-child(4), .stack-body td:nth-child(4){
  width: 12%;
  text-align: center;
}

.stack-header td:nth-child(5), .stack-body td:nth-child(5){
  width: 12%;
}

.stack-header td:nth-child(6), .stack-body td:nth-child(6){
  width: 5%;
}

.stack-header button{
  color: gray;
  background: rgb(54, 54, 54);
  border: 1px solid rgb(76, 117, 83);
  padding-left:0.5vw;
  margin-left:0.5vw;
  border-radius: 0.2vw;
  font-size: var(--fontSize4);
}

.stack-header button:hover {
  box-shadow: 0 0 0 1px rgb(127, 189, 137);
}

.stack-header button:active{
  transform: scale(0.92);
}

.stack-body input{
  font-size: var(--fontSize2);
  background-color:rgb(230, 230, 230);
  color:rgb(48, 47, 46);
  width:55%;
}

.stack-body button{
  background: white;
  border: 1px solid rgb(137, 231, 155);
  padding:0.1vw;
  border-radius: 0.3vw;
  font-size: var(--fontSize3);
}

.stack-body img{
  width: var(--fontSize2);
}

.stack-body button:hover {
  box-shadow: 0 0 0 1px rgb(127, 189, 137);
}

.stack-body button:active{
  transform: scale(0.92);
}

.stack-body button .tiptext{
  visibility: hidden;
  width:fit-content;
  padding: 0.5vw;
  background-color: black;
  color:lightgray;
  opacity: 0;
  transition: opacity 1s;
  border-radius: 0.2vw;
  position: absolute;
  z-index: 1; 
}

.stack-body button:hover .tiptext{
  visibility:visible;
  opacity:1;
}

.stack-body button:active .tiptext{
  visibility:hidden;
}


