.mfilter-container{
  background-color: #282c34;
  color:lightgray;
  margin: 1px;
  overflow: auto;
  min-height: 100px;
  border: 1px solid lightgray;
  padding: 0.5vw;
  border-radius: 5px;
}
.mfilter-table button {
  display: inline-block;
  background: rgb(22, 27, 41);
  color: #fff;
  border: 1px solid rgb(104, 153, 112);
  padding: 0.5vw 0.8vw;
  margin: 0.3vw;
  border-radius: 0.5vw;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--fontSize4);
  font-family: inherit;
}

.mfilter-table button:hover {
  box-shadow: 0 0 0 1px rgb(127, 189, 137);
}

.mfilter-table button:active {
  transform: scale(0.96);
}