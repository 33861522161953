.app-mprop h1{
    font-size: var(--fontSize2);
    margin-bottom: 5px;
}
.app-mprop p{
    font-size: var(--fontSize5);
}
.mprop-header{
    font-size: var(--fontSize3);
    color: rgb(223, 215, 215);
    background-color: rgb(71, 69, 73);
    border-radius: 5px;
    width:100%;
}
.mprop-header td:nth-child(1){
    width: 15%;
}

.mprop-header td:nth-child(2){
    width: 55%;
}

.mprop-header td:nth-child(3){
    width: 15%;
}

.mprop-header button{
    background: rgb(19, 25, 39);
    color: rgb(197, 197, 197);
    border: 1px solid rgb(96, 146, 105);
    padding: 0.5vw 0.4vw;
    border-radius: 1vw;
    cursor: pointer;
    font-size: var(--fontSize3);
}
.mprop-header button:hover {
    box-shadow: 0 0 0 1px rgb(127, 189, 137);
  }
.mprop-header button:active {
    transform: scale(0.96);
}
