:root{
  --fontSize0:calc(20px + 1.0vw);
  --fontSize1:calc(18px + 0.8vw);
  --fontSize2:calc(14px + 0.5vw);
  --fontSize3:calc(12px + 0.3vw);
  --fontSize4:calc(10px + 0.2vw);
  --fontSize5:calc(8px + 0.16vw);
  --fontSize6:calc(7px + 0.12vw);
}

/* use CSS custom properties ('variables') to limit max columns to 14; */
.grid-container {
  --repeat: auto-fit;
}
@media (min-width: calc(80px * 14)){
  .grid-container{
    --repeat:14;
  }
}
@media (max-width: calc(90px * 7)){
  .grid-container{
    --repeat:7;
  }
}
.grid-container{
  display: grid;
  grid-template-columns: repeat(var(--repeat, auto-fit), minmax(80px, 1fr));
  row-gap: 0.5vw;
  column-gap: 2vw;
  padding: 1vw;
  padding-top:0;
}


.App {
  text-align:left;
  background-color: #b5b7b9;
}

.app-header {
  grid-column: 1/-1;
  background-color: #282c34;
  /* display: flex; */
  justify-content: center;
  font-size: var(--fontSize0);
  color: white;
  font-weight:bold;
}

.App-link {
  color: #61dafb;
}

.app-light-cond{
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:flex-start; 
  width: 100%;

}

.app-spectra{
  grid-column: span 7;
  grid-row:span 2;
  width: 100%;
}

.app-stack{
  grid-column: span 7;
  grid-row: span 3;
  font-size: var(--fontSize3);
}

.app-appear{
  grid-column: span 4;
  font-size:var(--fontSize3);
  background-color: #282c34;
  color:rgb(255, 255, 255);
  overflow: auto;
  min-height: 100px;
  border: 1px solid lightgray;
  padding: 0.5vw;
  border-radius: 5px;
  text-align:right;
  flex-direction:column;
}

.app-mprop{
  grid-column: span 7;
  font-size: var(--fontSize4);
  width: 100%;
}

.btn {
  display: inline-block;
  background: rgb(22, 27, 41);
  color: #fff;
  border: 1px solid rgb(19, 153, 42);
  padding: 0.5vw 2vw;
  border-radius: 1vw;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--fontSize1);
  font-family: inherit;
}

.btn:hover {
  box-shadow: 0 0 0 1px rgb(9, 201, 41);
}

.btn:active {
  transform: scale(0.96);
}

.btn-block {
  display: block;
  /* width: 70%; */
}

.header-btn {
  border: 1px solid steelblue;
  padding: 10px 12px;
  margin: 5px;
  font-size: var(--fontSize0);
  font-weight:bold;
}

