.app-header {
  background-color: #282c34;
  /*min-height: 100vh;*/
  display: flex;
  /*flex-direction: column;*/
  align-content: stretch;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: var(--fontSize0);
  color: white;
  font-weight:bold;
}
.app-header h1 {
  font-size: var(--fontSize0);
}
.app-header table tbody tr td{
  /* border: 2px solid aqua; */
  text-align: center;
}

.app-header table{
  width: 100%;
}

.app-header img{
  height: 10vh;
  text-align:left;
}

.app-header button{
  display: inline-block;
  background: rgb(22, 27, 41);
  color: #fff;
  border: 1px solid steelblue;
  border-radius: 1vw;
  cursor: pointer;
  text-decoration: none;
  font-family: inherit;
  padding: 10px 12px;
  margin: 5px;
  font-size: var(--fontSize0);
  font-weight:bold;
}

.app-header button:hover{
  box-shadow: 0 0 0 1px rgb(127, 189, 137);
}

.app-header button:active{
  transform: scale(0.96);
}

.app-header a{
  font-size:var(--fontSize4);
  text-align: right;
  color:deepskyblue;
}