.container-mrs {
  height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  position: relative;
  width: 90%; 
}

.slider__track,
.slider__range,
.slider__title,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 5px;
  height: 0.5vw;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #588169;
  z-index: 2;
}

.slider__title {
  color: #dee2e6;
  font-size: var(--fontSize3);
  margin-top: -4.5vw;
  left: 0.2vw;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: var(--fontSize4);
  margin-top: 2.6vw;
  width:5vw;
}

.slider__left-value {
  left: 20vw;
  text-align: right;
}

.slider__right-value {
  right: 20vw;
  text-align: left;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;  /*so the two slider occupy the same space*/
  height: 0;
  width: 45%; 
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 1.5vw;
  width: 1.5vw;
  margin-top: 0.6vw;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 1.5px;
  width: 1.5px;
  margin-top: 0.6vw;
  pointer-events: all;
  position: relative;
}
