
.app-light-cond h2{
  font-size: var(--fontSize2);
  margin:0;
  margin-left:0.2vw;
}

.btn-light{
  font-size: var(--fontSize3);
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  padding: 10px;
  margin-top: 0.2vw;
  border-radius: 0.4vw;
  border-width: 2px;
}

.btn-calculate{
  font-size: var(--fontSize3);
  font-weight: bold;
  color:black;
  background-image: linear-gradient(to right, red,orange,yellow,green,dodgerblue,violet);
  padding: 10px;
  width: 100%;
  border-width: 3px;
  border-color: black;
  border-radius: 0.4vw;
}

.form {
  font-size: var(--fontSize3);
  width:100%;
}

.box1{
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}

.form-control input {
  font-size: var(--fontSize3);
}

.form-control select {
  font-size: var(--fontSize3);
}
